import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ImageTag from "gatsby-image";

const CardApp = () => {
  const images = useStaticQuery(graphql`
    query {
      background: file(name: { eq: "people-in-cafe" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image: file(name: { eq: "home-phone" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div>
      <div className="bg-[#0C2340] bg-opacity-80">
        <section className="relative text-white pt-16 overflow-hidden mx-10 md:mx-0">
          <div className="relative z-10 space-y-12 sm:space-y-24">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-row-dense lg:gap-24">
              <Heading />
              <FeaturedImage images={images} />
              <span className="col-span-3">
                <LaunchedFeatures />
                <span>
                <div className="relative">
                  <p className="text-md font-extrabold text-white pt-6">
                    Physical card to digital card & digital card to physical card *
                  </p>
                  <p className="text-sm text-gray-300">
                    * Only available on mastercard products
                  </p>
                </div>
                </span>
              </span>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

const Heading = () => (
  <div className="md:grid-cols-5 col-span-5 md:text-left">
    <h2>
      <span className="md:col-start 1 md:col-start-2 text-lg md:text-5xl font-extrabold tracking-tight text-left p-6 md:px-20">
        Branded Corporate App
      </span>
    </h2>
  </div>
);

const LaunchedFeatures = () => (
  <div class="md:col-span-3 mb-5 mt-0">
    <h3 className="text-2xl font-extrabold tracking-tight text-white sm:text-2xl pb-2">
      Launched
    </h3>
    <hr/>
    <ul className="font-bold list-outside ml-12 space-y-4 pt-1 nonbullet-point-listing" >
      <li>Company branded card.</li>
      <li>Company branded mobile app.</li>
      <li>Save cards to a mobile wallet.</li>
      <li>Balance and transaction history of either physical or digital card.</li>
      <li>Loyalty and rewards.</li>
    </ul>
  </div>
);

const FeaturedImage = ({images}) => (
  <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1 md:pt-0">
    <div className="pl-0 sm:pl-6 md:pl-16 lg:pl-0 lg:relative lg:h-full">
      <ImageTag fluid={images.image.sharp.fluid} className="w-full relative" />
    </div>
  </div>
);

export default CardApp;
